import React, { useState } from 'react';

import LocationAutocomplete from "./LocationAutocomplete";


import "./PopupForm.css";


interface FormData {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  companyName: string;
  companySegment: string;
  country: string;
  state: string;
  city: string;
}
interface PopupFormProps {
  onClose: () => void;
}

const PopupForm: React.FC<PopupFormProps> = ({ onClose }) => {
  const [canSend , setCanSend] = useState<boolean>(true);
  const [formData, setFormData] = useState<FormData>({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    companyName: '',
    companySegment: '',
    country: '',
    state: '',
    city: '',
  });

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    setCanSend(false);
    e.preventDefault();
    if (!validateForm()) {
      alert('Por favor, preencha todos os campos obrigatórios');
      setCanSend(true);
      return;
    }

    const dataToSend = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      phone: formData.phone,
      companyName: formData.companyName,
      companySegment: formData.companySegment,
      country: formData.country,
      state: formData.state,
      city: formData.city,
    };

    try {
      const response = await fetch('https://app.intranetmcs.com.br/api/enviar-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataToSend),
      });

      if (response.ok) {
        alert('Cadastro realizado com sucesso!');
        onClose();
        setCanSend(true);
      } else {
        setCanSend(true);
        throw new Error('Falha ao cadastrar.');
      }
    } catch(err: unknown) {
      if(typeof err === "string"){
        alert(err);
      } else if (err instanceof Error) {
        alert(err.message)
      }
      setCanSend(true);
    }
  };

  const validateForm = () => {
    return (
      formData.firstName.trim() !== '' &&
      formData.email.trim() !== '' &&
      formData.companyName.trim() !== '' &&
      formData.companySegment.trim() !== ''
    );
  };


  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    console.log("handling", event.target)
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const locationChange = (form: FormData) => {
    setFormData({
      ...formData,
      "country": form.country,
      "state": form.state,
      "city": form.city,
    });      

  };

  return (
    <div className="popup">
      <div className="popup-close-div" onClick={onClose}></div>
      <div className="popup-inner" onClick={() =>{return false}}>
        <h2>Cadastre-se</h2>
        <p>Insira os dados abaixo e receba um informativo completo</p>
        <form onSubmit={handleSubmit}>
          <fieldset>
            <legend>Dados Pessoais</legend>
            <input type="text" name="firstName" placeholder="* Primeiro nome" onChange={handleChange} />
            <input type="text" name="lastName" placeholder="Último nome" onChange={handleChange} />
            <input type="email" name="email" placeholder="* E-mail" onChange={handleChange} />
            <input type="tel" name="phone" placeholder="Telefone" onChange={handleChange} />
          </fieldset>
          <fieldset>
            <legend>Dados da empresa</legend>
            <input type="text" name="companyName" placeholder="* Nome" onChange={handleChange} />
            <input type="text" name="companySegment" placeholder="* Área de atuação" onChange={handleChange} />
          </fieldset>
          <fieldset>
            <legend>Localização</legend>
            <LocationAutocomplete name="location" value={formData} onChange={locationChange} label="" />
          </fieldset>

          <button type="submit" className="popup-send-button">Enviar</button>
          <button type="button" onClick={onClose} className="popup-close-button" disabled={!canSend} >
            X
          </button>
        </form>
      </div>
    </div>
  );
};

export default PopupForm;