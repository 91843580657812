import React, { useState } from 'react';

import IntranetButton from '../General/IntranetButton';
import InputField from './InputField';

interface FormData {
    email: string;
}

interface MailingButtonProps {
    buttonImage?: string;
    buttonText?: string;
    placeholderText?: string;
    onEmailSubmit: (e: FormData) => void;
}

const MailingButton: React.FC<MailingButtonProps> = ({ buttonImage, buttonText, placeholderText, onEmailSubmit }) => {
    buttonText = buttonText ? buttonText : "";
    buttonImage = buttonImage ? buttonImage : "";
    const [formData, setFormData] = useState<FormData>({
        email: '',
    });
    const [isValidEmail, setIsValidEmail] = useState<string>('');
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;


    const validEmail = () => {
        setIsValidEmail((emailRegex.test(formData.email) ? "Seu e-mail é válido" : "Por favor, digite um e-mail válido") + ".");
        return isValidEmail;
    }

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        const fieldValue = event.target.value;
        if(fieldValue === "") {
            setIsValidEmail("");
        } else {
            formData["email"] = fieldValue;
            if(validEmail()){
                
            };
        }
    };


    const handleEmailSubmit = (event: React.MouseEvent<HTMLDivElement>) => {
        event.preventDefault();
        if(validEmail()){
            setIsValidEmail("");
            const dataToSend = {
                email: formData.email,
              };
            onEmailSubmit(dataToSend);
        }
    };

    return (
        <div className="mailingButton">
            <InputField label="E-mail" placeholderText="Insira o seu e-mail" verticalPadding="15px" horizontalPadding="0px" onChange={handleEmailChange} />
            <IntranetButton text={buttonText} verticalPadding="15px" horizontalPadding="0px" backgroundColor="#014173" onClick={handleEmailSubmit} imageButton={buttonImage} />
            <p>{isValidEmail}</p>
        </div>
    );
};

export default MailingButton;