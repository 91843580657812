import React from 'react';

import Title from '../General/Title';
import MailingButton from '../General/MailingButton';

import './SendMail.css';
import "./../../index.css";


interface FormData {
  email: string;
}

const SendMail: React.FC = () => {
  const handleSubmit = async (formData: FormData) => {
    const dataToSend = {
      email: formData.email,
    };
    console.log(formData)
    try {
      const response = await fetch('https://app.intranetmcs.com.br/api/enviar-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataToSend),
      });

      if (response.ok) {
        alert('E-mail enviado com sucesso!');
      } else {
        throw new Error('Falha ao enviar e-mail.');
      }
    } catch(err: unknown) {
      if(typeof err === "string"){
        alert(err);
      } else if (err instanceof Error) {
        alert(err.message)
      }
    }
  };

  return (
    <section className="SendMail">
      <div className="Content">
        <div>
          <div>
            <Title type="h2" content="Quer saber mais?" />
            <Title type="p" content="Insira seu e-mail abaixo e receba um conteúdo completo sobre a nossa plataforma." />
            <MailingButton onEmailSubmit={handleSubmit} buttonText='' buttonImage="/assets/icon_sendmail.svg" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default SendMail;