import React from 'react';
import { Button } from "@mui/material";
import IntranetButton from "./IntranetButton"

import './SocialMedia.css';


const SocialMedia: React.FC = () => {
    return (
      <span className="socialMedia">
        <IntranetButton text="" title="LinkedIn" backgroundImageLink="/assets/icon_linkedin.svg" verticalPadding="24px" horizontalPadding="24px" isRounded={true} link="https://www.linkedin.com/company/mcs-markup-/mycompany/"></IntranetButton>
        <IntranetButton text="" backgroundImageLink="/assets/icon_facebook.svg" verticalPadding="24px" horizontalPadding="24px" isRounded={true} link="https://www.facebook.com/empresamcsmarkup"></IntranetButton>
        <IntranetButton text="" title="Instagram" backgroundImageLink="/assets/icon_instagram.svg" verticalPadding="24px" horizontalPadding="24px" isRounded={true} link="https://www.instagram.com/mcs_markup/"></IntranetButton>
      </span>
    );
};

export default SocialMedia;