import React from 'react';

import Title from "../General/Title"

import './Testimonial.css';

const Testimonial: React.FC = () => {
    return (
      <section className="Testimonial">
        <div className="Content">
          <div>
            <img src="/assets/andre_simoes_round.png"></img>
          </div>
          <div className="Citation">
            <Title type="h2" content='"O rápido crescimento da empresa trouxe diversos desafios, que motivaram o desenvolvimento de uma plataforma web que resolvesse as dores da gestão do negócio"' />
            <Title type="p" content="André Simões, " />
            <Title type="span" content="Sócio na MCS Markup" />
          </div>
        </div>
      </section>
    );
};

export default Testimonial;