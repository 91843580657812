import React, { useState } from 'react';

import Title from '../General/Title';
import IntranetButton from '../General/IntranetButton';
import MailingButton from '../General/MailingButton';

import './Highlights.css';
import "./../../index.css";

interface FormData {
  email: string;
}


const Highlights: React.FC = () => {
  const [formData, setFormData] = useState<FormData>({
    email: '',
  });

  const handleEmailSubmit = (fd: FormData) => {
    setFormData(fd)
    handleSubmit()
  }

  const handleSubmit = async () => {
    const dataToSend = {
      email: formData.email,
    };

    try {
      const response = await fetch('https://app.intranetmcs.com.br/api/enviar-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(dataToSend),
      });
      if (response.ok) {
        alert('E-mail enviado com sucesso!');
      } else {
        throw new Error('Falha ao enviar e-mail.');
      }
    } catch(err: unknown) {
      if(typeof err === "string"){
        alert(err);
      } else if (err instanceof Error) {
        alert(err.message)
      }
    }
  };

  return (
    <section className="Highlights">
      <div className="Content">
        <div>
          <div>
            <Title type="h2" content="Simples, intuitiva, eficaz." />
            <Title type="p" content="Uma plataforma completa e indispensável na gestão do negócio, com módulos essenciais e integrados, para gerar dados confiáveis que auxiliem na tomada de decisão." />
            <IntranetButton text="Solicitar Demonstração" verticalPadding="8px" horizontalPadding="22px" backgroundColor="#6bde42" hasPopup={true} />
            <MailingButton onEmailSubmit={handleEmailSubmit} buttonText="Quero saber mais" />
          </div>
          <img alt="imagem" src="/assets/hero.svg"></img>
        </div>
      </div>
    </section>
  );
};

export default Highlights;