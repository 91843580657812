import React, { useState, useEffect } from "react";
import { Select2, Select2Data, Select2UpdateValue } from "select2-react-component";
import axios from "axios";


interface FormData {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    companyName: string;
    companySegment: string;
    country: string;
    state: string;
    city: string;
  }

interface LocationAutocompleteProps {
    name: string;
    value: Object;
    onChange: (form: FormData) => void;
    label: string;
}

interface State {
    value: string;
    label: string;
}

interface City {
    value: string;
    label: string;
}

const LocationAutocomplete: React.FC<LocationAutocompleteProps> = ({
    name,
    value,
    onChange,
    label
}) => {
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState<State[]>([]);
    const [cities, setCities] = useState<City[]>([]);
    const [selectedCountry, setSelectedCountry] = useState<string>("");
    const [selectedState, setSelectedState] = useState<string>("");
    const [selectedCity, setSelectedCity] = useState<string>("");
    const [isLoadingCountry, setLoadingCountry] = useState<boolean>(false);
    const [isLoadingState, setLoadingState] = useState<boolean>(false);
    const [isLoadingCity, setLoadingCity] = useState<boolean>(false);

    const [formData, setFormData] = useState<FormData>({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        companyName: '',
        companySegment: '',
        country: '',
        state: '',
        city: '',
      });
    const isBrazil = selectedCountry === "BR";

    useEffect(() => {
        const fetchCountries = async () => {
            
            const response = await axios.get(
                "https://servicodados.ibge.gov.br/api/v1/localidades/paises"
            );

            const countriesList = response.data.map((country: any) => ({
                value: country.id["ISO-ALPHA-2"],
                label: country.nome,
            }));
            setCountries(countriesList);
            setLoadingCountry(false);
        };
        fetchCountries();
    }, []);

    const changeCountry = async (countryCode : string) => {        
        setLoadingCountry(true);
        setLoadingState(true);
        setSelectedCountry(countryCode);
        countries.forEach(country => {
            if(country["value"] === countryCode) {
                setFormData({
                    ...formData,
                    "country": country["label"],
                    "state": "",
                    "city": "",
                });
                return;
            }
        });
        setSelectedState("");
        setSelectedCity("");
        if (countryCode !== "BR" && countryCode !== "") {
            setStates([]);
            setCities([]);
        } else {
            const response = await axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados`);
            const statesList = response.data.map((state: any) => ({
                value: state.sigla,
                label: state.nome,
            }));
            setStates(statesList);
        }
        setLoadingCountry(false);
        setLoadingState(false);
    }

    const changeState = async (stateCode : string) => {
        setLoadingCity(true);
        setLoadingState(true);
        console.log("changing state")
        setSelectedState(stateCode);
        if(stateCode !== ""){
            states.forEach(state => {
                if(state["value"] === stateCode) {
                    setFormData({
                        ...formData,
                        "state": state["label"],
                        "city": "",
                    });
                    return;
                }
            });
        }
        setSelectedCity("");

        const response = await axios.get(
            `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${stateCode}/municipios`
        );
        const citiesList = response.data.map((city: any) => ({
            value: city.nome,
            label: city.nome,
        }));
        setCities(citiesList);
        setLoadingCity(false);
        setLoadingState(false);
    }

    const changeCity = async (cityCode : string) => {
        setLoadingCity(true);
        setSelectedCity(cityCode);
        if(cityCode !== ""){
            cities.forEach(city => {
                if(city["value"] === cityCode) {
                    formData["city"] = city["label"];
                    onChange(formData);
                    return;
                }
            });
        }
        setLoadingCity(false);
    }

    const updateCountry = async (value: Select2UpdateValue) => {
        changeCountry(value as string);
        return selectedCountry;
    }

    const updateState = async (value: Select2UpdateValue) => {
        changeState(value as string);
        return selectedState;
    }

    const updateCity = async (value: Select2UpdateValue) => {
        changeCity(value as string);
        return selectedCity;
    }

    const dataCountry: Select2Data = JSON.parse(JSON.stringify(countries));
    const dataState: Select2Data = JSON.parse(JSON.stringify(states));
    const dataCity: Select2Data = JSON.parse(JSON.stringify(cities));

    return (
        <>
            <div className={isLoadingCountry === true ? ("field loading") : ("field")}>
                <Select2 data={dataCountry} value={selectedCountry}
            update={value => updateCountry(value)} placeholder="País" ></Select2>
                <input
                    type="hidden"
                    id='country'
                    name='country'
                    value={formData.country}
                />
            </div>
            <input type="hidden" id='state' name='state' value={formData.state} />
            <div className={isLoadingState === true ? ("field loading") : ("field")}>
                {isBrazil ? (
                    <Select2 data={dataState} value={selectedState}
                    update={value => updateState(value)} placeholder="Estado" disabled={!selectedCountry}></Select2>
                ) : (
                    <input
                        type="text"
                        id={`${name}-state`}
                        name={`${name}-state`}
                        value={selectedState}
                        disabled={!selectedCountry}
                        onChange={(e) => setSelectedState(e.target.value)}
                        placeholder="Estado"
                        required
                    />
                )}
            </div>
            <input type="hidden" id='city' name='city' value={formData.city} />
            <div className={isLoadingCity === true ? ("field loading") : ("field")}>
                {isBrazil ? (
                    <Select2 data={dataCity} value={selectedCity}
                        update={value => updateCity(value)} placeholder="Cidade" disabled={!selectedState}></Select2>
                ) : (
                    <input
                        type="text"
                        id={`${name}-city`}
                        name={`${name}-city`}
                        value={selectedCity}
                        placeholder="Cidade"
                        disabled={!selectedState}
                        required
                    />
                )}
            </div>
        </>
    )
}


export default LocationAutocomplete;