import React, { useState } from 'react';

import Popup from './Popup';
import PopupForm from './PopupForm';

import './IntranetButton.css';

interface IntranetButtonProps {
  text: string;
  imageButton?: string;
  verticalPadding: string;
  horizontalPadding: string;
  link?: string;
  isOutline?: boolean;
  backgroundImageLink?: string;
  backgroundColor?: string;
  isRounded?: boolean;
  title?: string;
  label?: string;
  hasPopup?: boolean;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

const IntranetButton: React.FC<IntranetButtonProps> = ({ text, verticalPadding, horizontalPadding, link, isOutline, backgroundImageLink, backgroundColor, isRounded, title, label, onClick, hasPopup, imageButton }) => {

  backgroundColor = backgroundColor ? backgroundColor : "#183561";
  const bgColorHover = backgroundColor + "80";
  const bgColorNormal = backgroundColor;
  const [bgColor, setBackgroundColor] = useState<string>(bgColorNormal);
  const [isPopupOpen, setIsPopupOpen] = useState(false);


  const buttonStyle = {
    padding: `${verticalPadding} ${horizontalPadding}`,
    backgroundImage: `url(${backgroundImageLink})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundColor: bgColor,
    cursor: link || hasPopup ? 'pointer' : 'default',
  };
  

  const classButton = "intranet-button " + (isOutline ? "intranet-button-outline" : "") + " " + (isRounded ? 'intranet-button-round' : '')
  const handleOpenPopup = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };
  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if(hasPopup) {
      handleOpenPopup();
    } else if(link) {
      window.open(link, '_blank');
    } else if(onClick) {
      onClick(event);
    } else {
      event.preventDefault();
    }
  };
  const handleOver = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!bgColorHover) {
      event.preventDefault();
    } 
    setBackgroundColor(bgColorHover)
  };

  const handleLeave = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (!bgColorNormal) {
      event.preventDefault();
    } 
    setBackgroundColor(bgColorNormal)
  };

  return (
    <>
      <div onClick={handleClick} className={classButton} style={buttonStyle} title={title} onMouseOver={handleOver} onMouseOut={handleLeave}>
        {text && <div style={{ position: 'relative', zIndex: 1 }}>{text}</div>}
        {imageButton && <img src={imageButton} />}
      </div>
      {isPopupOpen && <PopupForm onClose={handleClosePopup} />}
    </>
  );
};

export default IntranetButton;