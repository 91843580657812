import React from 'react';

// import Menu from './Menu';
import Logo from '../General/Logo';
import SocialMedia from '../General/SocialMedia';

import './Footer.css';

const Copyright = () => {
  return (
    <span className="copyright">&copy; 2023 - Nossa Intranet. Todos os direitos reservados.</span>
  );
}

const Footer: React.FC = () => {
    return (
      <footer className="Footer">
        <div className="Content">
          <div>
            {/* <Menu /> */}
          </div>
          <div>
            <Copyright/>
            <SocialMedia />
            <div className="footerLogo">
              <Logo />
            </div>
          </div>
        </div>
      </footer>
    );
};

export default Footer;