import React from 'react';

import Logo from '../General/Logo';
import IntranetButton from '../General/IntranetButton';

import './Header.css';

const Header: React.FC = () => {
    return (
      <header className="Content">
        <Logo />
        <IntranetButton text="Solicitar Demonstração" verticalPadding="8px" horizontalPadding="22px" backgroundColor="#6bde42" hasPopup={true} />
      </header>
    );
};


export default Header;