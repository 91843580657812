import React from 'react';

import "./Logo.css";

const Logo: React.FC = () => {
    return (
        <div className="App-logo">
          <span><span className="intranet-green">nos</span>sa</span>
          <span>intra<span className="intranet-green">net</span><span className="intranet-orange">.</span></span>
        </div>
    );
};

export default Logo;



