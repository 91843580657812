import React from 'react';

import Title from "../General/Title"

interface BusinessCardProps {
  title: string;
  content: string;
  image?: string;
}

const BusinessCard: React.FC<BusinessCardProps> = ({
  title,
  content,
  image
}) => {
  image = image ? image : "#";
  return (
    <div className="BusinessCard">
      <img className="image" src={image}></img>
      <Title type="h3" content={title}></Title>
      <Title type="p" content={content}></Title>
    </div>
  );
};

export default BusinessCard;
