import React from 'react';


interface TagProps {
  type: keyof JSX.IntrinsicElements;
  content: string;
}

const Title: React.FC<TagProps> = ({type, content}) => {
  const Tag = type;
  return <Tag>{content}</Tag>;
};

export default Title;