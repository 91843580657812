import React, { useState, useEffect } from 'react';
import Title from '../General/Title';
import BusinessCard from '../General/BusinessCard';
// import IntranetButton from '../General/IntranetButton';
import './PageServices.css';

const PageServices: React.FC = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 430);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 430);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const titleContent = isMobile ? "Extraia margens de seus projetos, com autonomia e controle." : "A Intranet permite que você transforme a gestão dos seus projetos, dando autonomia e controle ao seu negócio.";

  return (
    <section className="PageServices">
      <div className="Content">
        <Title type="h2" content={titleContent}></Title>
        <div className="content-cards">
          <BusinessCard title="Gestão de Projetos" content="Para um controle de propostas, cadastros de clientes, geração de oportunidades automáticas, controle de faturamentos e acompanhamento de margem." image="/assets/Icon_project_management.svg"></BusinessCard>
          <BusinessCard title="Apontamento de Horas" content="Apontamento de horas por projetos e controle de horas extras da equipe com relatórios completos." image="/assets/Icon_timekeeping.svg"></BusinessCard>
          <BusinessCard title="Comunicação Interna" content="Solicitação de compras e reembolso, ouvidoria, acesso a políticas e procedimentos e abertura de chamados." image="/assets/Icon_internal_communication.svg"></BusinessCard>
        </div>
        <img alt="imagem" src="/assets/intranet_screen.svg"></img>
        
        {/* <IntranetButton text="Ver Todos os Serviços" verticalPadding="32px" horizontalPadding="48px" outline={true} /> */}
      </div>
    </section>
  );
};

export default PageServices;
