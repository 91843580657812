import React from 'react';

import Title from "../General/Title"
import BusinessCard from "../General/BusinessCard"
import IntranetButton from "../General/IntranetButton"

import './Explanation.css';

const Explanation: React.FC = () => {
    return (
      <section className="Explanation">
        <div className="Content">
          <Title type="h2" content="Como a nossa plataforma pode ajudar sua empresa e seus negócios"></Title>
          <div className="explanation-text">
            <BusinessCard title="Gestão" content="Maior monitoramento das horas incorridas e do faturamento com geração de relatórios confiáveis para tomada de decisão" image="/assets/management.svg"></BusinessCard>
            <BusinessCard title="Economia" content="Menos tempo gasto com trocas de e-mails, planilhas, controles e atividades administrativas" image="/assets/economy.svg"></BusinessCard>
            <BusinessCard title="Customização" content="Intuitivo e flexível, sendo possível adequá-lo a identidade da sua empresa" image="/assets/customization.svg"></BusinessCard>
          </div>
          {/* <IntranetButton text="Ver Todos os Serviços" verticalPadding="32px" horizontalPadding="48px" isOutline={true} /> */}
        </div>
      </section>
    );
};

export default Explanation;