import React from 'react';

interface InputFieldProps {
    value?: string;
    label?: string;
    placeholderText?: string;
    verticalPadding?: string;
    horizontalPadding?: string;
    backgroundImageLink?: string;
    backgroundColor?: string;
    isRounded?: boolean;
    title?: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  }

  const InputField: React.FC<InputFieldProps> = ({ value, label, placeholderText = '', verticalPadding, horizontalPadding, backgroundImageLink, backgroundColor, isRounded, title, onChange }) => {
    return (
      <div className="inputField">
        <label>{label}</label>
        <input type="text" value={value} placeholder={placeholderText} onChange={onChange} />
      </div>
    );
  };
  
  export default InputField;