import React from 'react';

import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Highlights from './components/Highlights/Highlights';
import Testimonial from './components/Testimonial/Testimonial';
import Explanation from './components/Explanation/Explanation';
import SendMail from './components/SendMail/SendMail';
// import Cards from './components/Cards/Cards';
// import Numbers from './components/Numbers/Numbers';
import PageServices from './components/PageServices/PageServices';


import './App.css';

const App: React.FC = () => {
  return ( 
    <>
      <Sticky>
        <Header />
      </Sticky>
      <Highlights />
      <Testimonial />
      <Explanation />
      <PageServices />
      {/* <Cards /> */}
      {/* <Numbers /> */}
      <SendMail />
      <Footer />
    </>
  );
};


interface StickyProps {
  children: React.ReactNode;
}

const Sticky: React.FC<StickyProps> = ({ children }) => {
  const [isSticky, setIsSticky] = React.useState(false);

  const handleScroll = () => {
    setIsSticky(window.scrollY > 0);
  };

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={`sticky ${isSticky ? 'isSticky' : ''}`}>
      {children}
    </div>
  );
};

export default App